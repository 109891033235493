import React, { ReactElement, useState, useEffect } from "react"
import SetPasswordPage, {
  ErrorDisplay,
  LoadingSetPassword as LoadingDisplay,
} from "../../components/views/sign-in/SetPasswordPage"
import { useUser } from "../../providers/UserProvider"
import { navigate } from "gatsby"

export default function HandleEmailActionPage(): ReactElement {
  const [oobCode, setOobCode] = useState<string | null | undefined>(undefined)
  const [mode, setMode] = useState<string | null | undefined>(undefined)
  const { user } = useUser()

  useEffect(() => {
    if (typeof window !== "undefined" && window.location) {
      setOobCode(new URL(window.location.href).searchParams.get("oobCode"))
      setMode(new URL(window.location.href).searchParams.get("mode"))
    }
  }, [setOobCode, setMode])

  if (user) {
    // Already authenticated
    navigate("/application", {
      replace: true,
    })
  }

  if (
    oobCode === undefined ||
    mode === undefined ||
    user === undefined ||
    user
  ) {
    // Still loading or navigating away
    return <LoadingDisplay />
  }

  if (oobCode === null) {
    return (
      <ErrorDisplay>
        This link is missing your authentication code.
      </ErrorDisplay>
    )
  }

  if (mode === null) {
    return <ErrorDisplay>This link is missing the mode.</ErrorDisplay>
  }

  switch (mode) {
    case "resetPassword":
      return <SetPasswordPage oobCode={oobCode} />
    case "recoverEmail":
    case "verifyEmail":
      return <ErrorDisplay>These actions are unsupported.</ErrorDisplay>
    default:
      return <ErrorDisplay>This link is invalid.</ErrorDisplay>
  }
}
