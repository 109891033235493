import React, { ReactElement, useState, useEffect, FC } from "react"
import { navigate } from "gatsby"
import SEO from "../../SEO"
import Box from "@mui/material/Box"
import T from "@mui/material/Typography"
import Skeleton from "../../Skeleton"
import { useUser } from "../../../providers/UserProvider"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import SetPasswordForm from "../SetPasswordForm"
import Button from "../../design-system/Button"
import NoScript from "../../NoScript"
import ButtonWrapper from "../../ButtonWrapper"

export const LoadingSetPassword: React.FC = () => {
  return (
    <div data-test-id="loading-reset-pass" role="presentation">
      <NoScript />
      <T variant="h1">
        <Skeleton sx={{ maxWidth: "18rem" }} />
      </T>
      <T variant="body1">
        <Skeleton sx={{ maxWidth: "26rem" }} />
      </T>
      <Box sx={{ width: "100%", maxWidth: "450px", paddingTop: "1rem" }}>
        <Skeleton sx={{ maxWidth: "8rem" }} height="3em" />
        <Skeleton height="4em" variant="rectangular" />
        <Skeleton sx={{ maxWidth: "13rem", marginTop: "1rem" }} height="3em" />
        <Skeleton height="3em" variant="rectangular" />
      </Box>
      <Skeleton sx={{ maxWidth: "10rem", fontSize: "4rem" }} />
    </div>
  )
}

interface SetPasswordProps {
  oobCode: string
}

export const SetPassword: React.FC<SetPasswordProps> = ({ oobCode }) => {
  const { resetPassword } = VerifyResetPassword.useUser()
  const [passwordReset, setPasswordReset] = useState<boolean>(false)

  if (passwordReset) {
    // We're navigating away, don't show anything.
    return <></>
  }

  const handleSetPassword = async (password: string) => {
    await resetPassword(oobCode, password)
    navigate("/sign-in", { replace: true, state: { newPasswordSet: true } })
    setPasswordReset(true)
  }

  // Collect the new password
  return (
    <>
      <T variant="h1" component="h1" data-test-id="set-password-heading">
        Create a password
      </T>
      <SetPasswordForm handleChangePassword={handleSetPassword} />
    </>
  )
}

export const ErrorDisplay: FC = ({ children }): ReactElement => {
  return (
    <>
      <T variant="h1" component="h1" data-test-id="password-error-heading">
        <ErrorOutlineIcon fontSize="large" />
        Error
      </T>
      <T variant="body1">{children}</T>
      <ButtonWrapper>
        <Button onClick={() => navigate("/sign-in")} role="link">
          Back to sign in
        </Button>
      </ButtonWrapper>
    </>
  )
}

interface VerifyRestPasswordProps {
  oobCode: string
}

export const VerifyResetPassword = ({
  oobCode,
}: VerifyRestPasswordProps): ReactElement => {
  const [verifyResetError, setVerifyResetError] = useState<string | undefined>()
  const [verifiedCode, setVerifiedCode] = useState<boolean>(false)
  const { verifyResetPassword } = VerifyResetPassword.useUser()

  // Verify the code is valid.
  useEffect(() => {
    // Don't rerun if already verified.
    if (verifiedCode) return

    // Try to verify the code
    verifyResetPassword(oobCode)
      .then(() => {
        setVerifiedCode(true)
      })
      .catch(() => {
        setVerifyResetError("app/invalid-code")
      })
  }, [oobCode, verifyResetPassword, verifiedCode])

  // If the code looks valid, let's collect their new password.
  if (verifiedCode) return <SetPassword oobCode={oobCode} />

  // If there was an error verifying the reset code, throw an error.
  if (verifyResetError)
    return (
      <ErrorDisplay>
        There was a problem verifying your set password link.
      </ErrorDisplay>
    )

  // Loading state
  return <LoadingSetPassword />
}

VerifyResetPassword.useUser = useUser

interface SetPasswordPageProps {
  oobCode: string
}

export default function VerifyResetPasswordPage({
  oobCode,
}: SetPasswordPageProps): React.ReactElement {
  return (
    <>
      <SEO title="Set your new password" />
      <VerifyResetPassword oobCode={oobCode} />
    </>
  )
}
